<template>
  <div>
    <b-card class="row-card">
      <b-row>
        <h5 class="mx-2">Tarih Seçimi : </h5>
        <b-form-group label="Başlangıç" label-for="basicInput" class="mr-1 mb-50">
          <flat-pickr
              v-model="baslangic"
              class="form-control"
          />
        </b-form-group>
        <b-form-group label="Bitiş" label-for="basicInput" class="mb-50">
          <flat-pickr
              v-model="bitis"
              class="form-control"
          />
        </b-form-group>
      </b-row>
    </b-card>
    <b-card-actions
        v-if="isData"
        ref="first-card"
        class="list-card"
        @refresh="refreshStop('first-card')"
    >
      <b-row class="mt-50 mb-5">
        <div class="card-header-row" :style="{backgroundColor: $store.state.colors.headerRowColor}">
          <b-col cols="12" class="d-flex justify-content-between">
            <h4 class="text-white d-inline-block card-title">{{ firstTitle }}</h4>
          </b-col>
        </div>
      </b-row>
      <hr>
      <b-row class="justify-content-evenly">
        <b-col
            xl="3"
            md="3"
            sm="4"
        >
          <b-media no-body>
            <b-media-aside
                class="mr-2"
            >
              <b-avatar
                  size="48"
                  variant="success"
                  class="pull-up"
              >
                <font-awesome-icon icon="arrow-trend-up" class="fa-2x"/>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0 text-nowrap">
                {{
                  Number(finans.toplamGelir)
                }}
                <font-awesome-icon icon="turkish-lira-sign"/>
              </h4>
              <strong class="mb-0">
                Toplam Gelir
              </strong>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
            xl="3"
            md="3"
            sm="4"
            class="my-lg-0 my-md-0 my-sm-0 my-1"
        >
          <b-media no-body>
            <b-media-aside
                class="mr-2"
            >
              <b-avatar
                  size="48"
                  variant="info"
                  class="pull-up"
              >
                <font-awesome-icon icon="arrow-trend-up" class="fa-2x"/>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0 text-nowrap">
                {{
                  Number(finans.toplamGider)
                }}
                <font-awesome-icon icon="turkish-lira-sign"/>
              </h4>
              <strong class="mb-0">
                Toplam Gider
              </strong>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
            xl="3"
            md="3"
            sm="4"
        >
          <b-media no-body>
            <b-media-aside
                class="mr-2"
            >
              <b-avatar
                  size="48"
                  variant="secondary"
                  class="pull-up bg-primary bg-lighten-2"
              >
                <font-awesome-icon icon="arrow-trend-up" class="fa-2x"/>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0 text-nowrap">
                {{
                  Number(finans.toplamHavale)
                }}
                <font-awesome-icon icon="turkish-lira-sign"/>
              </h4>
              <strong class="mb-0">
                Toplam Havale
              </strong>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
            xl="3"
            md="3"
            sm="4"
            class="my-lg-0 my-md-0 my-sm-1 my-1"
        >
          <b-media no-body>
            <b-media-aside
                class="mr-2"
            >
              <b-avatar
                  size="48"
                  variant="secondary"
                  class="pull-up bg-success bg-darken-2"
              >
                <font-awesome-icon icon="arrow-trend-up" class="fa-2x"/>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0 text-nowrap">
                {{
                  Number(finans.adisyonSayisi)
                }}
                <font-awesome-icon icon="turkish-lira-sign"/>
              </h4>
              <strong class="mb-0">
                Adisyon Sayısı
              </strong>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-actions>
    <b-card-actions
        v-if="isData"
        ref="second-card"
        class="list-card"
        @refresh="refreshStop('second-card')"
    >
      <b-row class="mt-50 mb-5">
        <div class="card-header-row" :style="{backgroundColor: $store.state.colors.headerRowColor}">
          <b-col cols="12" class="d-flex justify-content-between">
            <h4 class="text-white d-inline-block card-title">{{ secondTitle }}</h4>
          </b-col>
        </div>
      </b-row>
      <hr>
      <b-tabs v-model="tabs">
        <b-tab active title="Gelir Kayıtları">
          <b-row>
            <b-col lg="6" md="6" sm="6" cols="12">
              <b-form-group class="mb-md-0 number-of-records" size="sm">
                <b-input-group
                    size="sm"
                    prepend="Gösterilecek kayıt sayısı"
                >
                  <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                      @change="perPageChange($event)"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col lg="6" md="6" sm="6" cols="12">
              <b-input-group size="sm" class="search-bar">
                <b-input-group-prepend>
                  <b-button
                      variant="outline-primary"
                      size="sm"
                      disabled
                      class="search-input mb-0"
                      :style="{height: width < 768 ? '30px':''}"
                  >
                    Ara
                  </b-button>
                </b-input-group-prepend>
                <b-form-input id="filterInput" v-model="filter" type="search" autocomplete="off"
                              @input="filterInput($event)"
                />
              </b-input-group>
            </b-col>
          </b-row>
          <hr>
          <b-table
              striped
              hover
              empty-text="Kayıt Bulunamadı"
              show-empty
              responsive
              class="position-relative kasa-rapor-table mt-1"
              :per-page="perPage"
              :items="tableList"
              :fields="gelirFields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              @row-clicked="goAdisyonDetay"
          >
            <template #cell(userName)="data">
              <font-awesome-icon icon="user"/>
              <p class="d-inline-block font-weight-bolder ml-50 m-0">{{ data.item.userName }}</p>
            </template>
            <template #cell(odemeTuru)="data">
              <p class="m-0">{{ getOdemeTuru(data.item.odemeTuru) }}</p>
            </template>
            <template #cell(tutar)="data">
              <p class="font-weight-bolder m-0">{{ data.item.tutar }} TL</p>
            </template>
            <template #cell(aciklama)="data">
              <p class="m-0">{{ data.item.aciklama }}</p>
            </template>
            <template #cell(kayitTarih)="data">
              <p class="m-0 font-weight-bolder">{{ data.item.kayitTarih.slice(0, 16) }}</p>
            </template>
          </b-table>
          <hr>
          <b-row class="d-flex align-items-center mt-1">
            <b-col cols="6">
              <p class="text-muted">Toplam {{ totalRows || 0 }} Kayıt bulundu</p>
            </b-col>
            <b-col cols="6">
              <b-pagination
                  v-model="currentPage"
                  @change="currentPageChange($event)"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0 float-right"
              />
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Gider Kayıtları">
          <b-row>
            <b-col lg="6" md="6" sm="6" cols="12">
              <b-form-group class="mb-md-0 number-of-records" size="sm">
                <b-input-group
                    size="sm"
                    prepend="Gösterilecek kayıt sayısı"
                >
                  <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                      @change="perPageChange($event)"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col lg="6" md="6" sm="6" cols="12">
              <b-input-group size="sm" class="search-bar">
                <b-input-group-prepend>
                  <b-button
                      variant="outline-primary"
                      size="sm"
                      disabled
                      class="search-input mb-0"
                      :style="{height: width < 768 ? '30px':''}"
                  >
                    Ara
                  </b-button>
                </b-input-group-prepend>
                <b-form-input id="filterInput" v-model="filter" type="search" autocomplete="off"
                              @input="filterInput($event)"
                />
              </b-input-group>
            </b-col>
          </b-row>
          <hr>
          <b-table
              striped
              hover
              empty-text="Kayıt Bulunamadı"
              show-empty
              responsive
              class="position-relative kasa-rapor-table mt-1"
              :per-page="perPage"
              :items="tableList"
              :fields="giderFields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
          >
            <template #cell(userName)="data">
              <font-awesome-icon icon="user"/>
              <p class="d-inline-block ml-50 m-0">{{ data.item.userName }}</p>
            </template>
            <template #cell(tutar)="data">
              <strong v-if="data.item.tutar" class="ml-50">
                {{ data.item.tutar }}
                <font-awesome-icon icon="turkish-lira-sign"/>
              </strong>
            </template>
            <template #cell(aciklama)="data">
              <p class="m-0">{{ data.item.aciklama }}</p>
            </template>
            <template #cell(fisNo)="data">
              <p>{{ data.item.fisNo }}</p>
            </template>
            <template #cell(kayitTarih)="data">
              <p class="m-0">{{ data.item.kayitTarih.slice(0, 16) }}</p>
            </template>
          </b-table>
          <hr>
          <b-row class="d-flex align-items-center mt-1">
            <b-col cols="6">
              <p class="text-muted">Toplam {{ totalRows || 0 }} Kayıt bulundu</p>
            </b-col>
            <b-col cols="6">
              <b-pagination
                  v-model="currentPage"
                  @change="currentPageChange($event)"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0 float-right"
              />
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Havale Listesi">
          <b-row>
            <b-col lg="6" md="6" sm="6" cols="12">
              <b-form-group class="mb-md-0 number-of-records" size="sm">
                <b-input-group
                    size="sm"
                    prepend="Gösterilecek kayıt sayısı"
                >
                  <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                      @change="perPageChange($event)"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col lg="6" md="6" sm="6" cols="12">
              <b-input-group size="sm" class="search-bar">
                <b-input-group-prepend>
                  <b-button
                      variant="outline-primary"
                      size="sm"
                      disabled
                      class="search-input mb-0"
                      :style="{height: width < 768 ? '30px':''}"
                  >
                    Ara
                  </b-button>
                </b-input-group-prepend>
                <b-form-input id="filterInput" v-model="filter" type="search" autocomplete="off"
                              @input="filterInput($event)"
                />
              </b-input-group>
            </b-col>
          </b-row>
          <hr>
          <b-table
              striped
              hover
              empty-text="Kayıt Bulunamadı"
              show-empty
              responsive
              class="position-relative kasa-rapor-table mt-1"
              :per-page="perPage"
              :items="tableList"
              :fields="havaleFields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              @row-clicked="goAdisyonDetay"
          >
            <template #cell(adisyonID)="data">
              <p class="d-inline-block font-weight-bolder ml-50 m-0"
                 :class="data.item.adisyonID && data.item.adisyonID !=='0' ? 'text-success': ''"
              >
                {{ data.item.adisyonID && data.item.adisyonID !== '0' ? data.item.adisyonID : 'Departman Geliri' }}
              </p>
            </template>
            <template #cell(userName)="data">
              <font-awesome-icon icon="user"/>
              <p class="d-inline-block font-weight-bolder ml-50 m-0">
                {{ data.item.userName }}
              </p>
            </template>
            <template #cell(isim)="data">
              <p class="font-weight-bolder m-0">{{ data.item.isim }} {{ data.item.soyIsim }}</p>
            </template>
            <template #cell(departmanAdi)="data">
              <p class="m-0 font-weight-bolder">{{ data.item.departmanAdi }}</p>
            </template>
            <template #cell(tutar)="data">
              <p class="d-inline-block m-0 font-weight-bolder mr-50">{{ data.item.tutar }} TL</p>
            </template>
            <template #cell(aciklama)="data">
              <p class="m-0">{{ data.item.aciklama }}</p>
            </template>
            <template #cell(kayitTarih)="data">
              <p class="m-0">{{ data.item.kayitTarih.slice(0, 16) }}</p>
            </template>
          </b-table>
          <hr>
          <b-row class="d-flex align-items-center mt-1">
            <b-col cols="6">
              <p class="text-muted">Toplam {{ totalRows || 0 }} Kayıt bulundu</p>
            </b-col>
            <b-col cols="6">
              <b-pagination
                  v-model="currentPage"
                  @change="currentPageChange($event)"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0 float-right"
              />
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Tahsilat Listesi">
          <b-row>
            <b-col lg="6" md="6" sm="6" cols="12">
              <b-form-group class="mb-md-0 number-of-records" size="sm">
                <b-input-group
                    size="sm"
                    prepend="Gösterilecek kayıt sayısı"
                >
                  <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                      @change="perPageChange($event)"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col lg="6" md="6" sm="6" cols="12">
              <b-input-group size="sm" class="search-bar">
                <b-input-group-prepend>
                  <b-button
                      variant="outline-primary"
                      size="sm"
                      disabled
                      class="search-input mb-0"
                      :style="{height: width < 768 ? '30px':''}"
                  >
                    Ara
                  </b-button>
                </b-input-group-prepend>
                <b-form-input id="filterInput" v-model="filter" type="search" autocomplete="off"
                              @input="filterInput($event)"
                />
              </b-input-group>
            </b-col>
          </b-row>
          <hr>
          <b-table
              striped
              hover
              empty-text="Kayıt Bulunamadı"
              show-empty
              responsive
              class="position-relative kasa-rapor-table mt-1"
              :per-page="perPage"
              :items="tableList"
              :fields="tahsilatFields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
          >
            <template #cell(userName)="data">
              <font-awesome-icon icon="user"/>
              <p class="d-inline-block ml-50 m-0">
                {{ data.item.userName }}
              </p>
            </template>
            <template #cell(unvan)="data">
              <p class="font-weight-bolder m-0">{{ data.item.unvan }}</p>
            </template>
            <template #cell(isim)="data">
              <p class="m-0">{{ data.item.isim }}</p>
            </template>
            <template #cell(tutar)="data">
              <p class="d-inline-block m-0 font-weight-bolder mr-50">{{ data.item.tutar }}</p>
              <font-awesome-icon icon="turkish-lira-sign"/>
            </template>
            <template #cell(tahsilatTuru)="data">
              <p class="d-inline-block m-0 font-weight-bolder mr-50">{{ getOdemeTuru(data.item.tahsilatTuru) }}</p>
              <font-awesome-icon icon="turkish-lira-sign"/>
            </template>
            <template #cell(aciklama)="data">
              <p class="m-0">{{ data.item.aciklama }}</p>
            </template>
            <template #cell(kayitTarih)="data">
              <p class="m-0">{{ data.item.kayitTarih.slice(0, 16) }}</p>
            </template>
            <template #cell(kasaID)="data">
              <b-button
                  variant="gradient-danger"
                  class="btn-icon rounded-circle mr-50"
                  @click="itemRemove(data.item,3)"
              >
                <font-awesome-icon icon="trash"/>
              </b-button>
            </template>
          </b-table>
          <hr>
          <b-row class="d-flex align-items-center mt-1">
            <b-col cols="6">
              <p class="text-muted">Toplam {{ totalRows || 0 }} Kayıt bulundu</p>
            </b-col>
            <b-col cols="6">
              <b-pagination
                  v-model="currentPage"
                  @change="currentPageChange($event)"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0 float-right"
              />
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Adisyon Listesi">
          <b-row>
            <b-col lg="6" md="6" sm="6" cols="12">
              <b-form-group class="mb-md-0 number-of-records" size="sm">
                <b-input-group
                    size="sm"
                    prepend="Gösterilecek kayıt sayısı"
                >
                  <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                      @change="perPageChange($event)"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col lg="6" md="6" sm="6" cols="12">
              <b-input-group size="sm" class="search-bar">
                <b-input-group-prepend>
                  <b-button
                      variant="outline-primary"
                      size="sm"
                      disabled
                      class="search-input mb-0"
                      :style="{height: width < 768 ? '30px':''}"
                  >
                    Ara
                  </b-button>
                </b-input-group-prepend>
                <b-form-input id="filterInput" v-model="filter" type="search" autocomplete="off"
                              @input="filterInput($event)"
                />
              </b-input-group>
            </b-col>
          </b-row>
          <hr>
          <b-table
              striped
              hover
              empty-text="Kayıt Bulunamadı"
              show-empty
              responsive
              class="position-relative kasa-rapor-table mt-1"
              :per-page="perPage"
              :items="tableList"
              :fields="adisyonFields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              @row-clicked="goAdisyonDetay"
          >
            <template #cell(userName)="data">
              <font-awesome-icon icon="user"/>
              <p class="d-inline-block ml-50 m-0">
                {{ data.item.userName }}
              </p>
            </template>
            <template #cell(toplamTutar)="data">
              <p class="m-0 d-inline-block font-weight-bolder mr-50">{{ data.item.toplamTutar }}</p>
              <font-awesome-icon icon="turkish-lira-sign"/>
            </template>
            <template #cell(adisyonDurum)="data">
              <b-badge :variant="data.item.adisyonDurum === '0'?'light-primary':'light-info'">
                {{ data.item.adisyonDurum === '0' ? 'Kapalı' : 'Açık' }}
              </b-badge>
            </template>
            <template #cell(durum)="data">
              <b-badge :variant="data.item.durum === '0'?'light-warning':'light-success'">
                {{ data.item.durum === '0' ? 'Bekleniyor' : 'Tamamlandı' }}
              </b-badge>
            </template>
            <template #cell(masaAdi)="data">
              <p class="m-0">{{ data.item.masaAdi }}</p>
            </template>
            <template #cell(kayitTarih)="data">
              <p class="m-0">{{ data.item.kayitTarih.slice(0, 16) }}</p>
            </template>
          </b-table>
          <hr>
          <b-row class="d-flex align-items-center mt-1">
            <b-col cols="6">
              <p class="text-muted">Toplam {{ totalRows || 0 }} Kayıt bulundu</p>
            </b-col>
            <b-col cols="6">
              <b-pagination
                  v-model="currentPage"
                  @change="currentPageChange($event)"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0 float-right"
              />
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-card-actions>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BMedia,
  BMediaAside,
  BMediaBody,
  BTabs,
  BTab,
  BButton,
  BRow,
  BCol,
  BModal,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BCardText,
  BFormCheckbox,
  BFormRadio,
  BSpinner,
  VBTooltip,
  BBadge, BInputGroup, BPagination, BInputGroupPrepend, BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import axios from 'axios'
import flatPickr from 'vue-flatpickr-component'
import { useWindowSize } from '@vueuse/core'
import router from '@/router'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr
flatpickr.localize(Turkish)
flatpickr(flatPickr)

export default {
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
  components: {
    BFormSelect,
    BInputGroupPrepend,
    BPagination,
    BInputGroup,
    BCard,
    flatPickr,
    BTable,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BBadge,
    BSpinner,
    ToastificationContent,
    BFormRadio,
    BFormCheckbox,
    BCardText,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BCardActions,
    BRow,
    BCol,
    BButton,
    BTabs,
    BTab,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      isData: false,
      tabs: 0,
      baslangic: null,
      bitis: null,
      gelirFields: [
        {
          key: 'userName',
          label: 'İşlem Sahibi',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center', 'text-nowrap'],
          thStyle: {
            width: '15%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },
        },
        {
          key: 'odemeTuru',
          label: 'Ödeme Türü',
          sortable: true,
          thStyle: {
            width: '20%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '20%' },
        },
        {
          key: 'tutar',
          label: 'Tutar',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center', 'text-nowrap'],
          thStyle: {
            width: '15%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },
        },
        {
          key: 'aciklama',
          label: 'Açıklama',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center', 'text-nowrap'],
          thStyle: {
            width: '30%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '30%' },

        },
        {
          key: 'kayitTarih',
          label: 'Tarih',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center', 'text-nowrap'],
          thStyle: {
            width: '20%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '20%' },

        },
      ],
      giderFields: [
        {
          key: 'userName',
          label: 'İşlem Sahibi',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center', 'text-nowrap'],
          thStyle: {
            width: '20%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '20%' },
        },
        {
          key: 'tutar',
          label: 'Tutar',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center', 'text-nowrap'],
          thStyle: {
            width: '15%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },
        },
        {
          key: 'aciklama',
          label: 'Açıklama',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center', 'text-nowrap'],
          thStyle: {
            width: '30%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '30%' },

        },
        {
          key: 'fisNo',
          label: 'Fiş No',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center', 'text-nowrap'],
          thStyle: {
            width: '15%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },

        },
        {
          key: 'kayitTarih',
          label: 'Tarih',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center', 'text-nowrap'],
          thStyle: {
            width: '20%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '20%' },

        },
      ],
      tahsilatFields: [
        {
          key: 'userName',
          label: 'İşlem Sahibi',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center', 'text-nowrap'],
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'unvan',
          label: 'Unvan',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center', 'text-nowrap'],
          thStyle: {
            width: '15%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },
        },
        {
          key: 'isim',
          label: 'isim',
          thClass: ['text-center'],
          tdClass: ['text-center', 'text-nowrap'],
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'tutar',
          label: 'Tutar',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center', 'text-nowrap'],
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'tahsilatTuru',
          label: 'Tahsilat Türü',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center', 'text-nowrap'],
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'aciklama',
          label: 'Açıklama',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center', 'text-nowrap'],
          thStyle: {
            width: '20%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '20%' },

        },
        {
          key: 'kayitTarih',
          label: 'Tarih',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center', 'text-nowrap'],
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },

        },
        {
          key: 'kasaID',
          label: 'İşlemler',
          sortable: false,
          thClass: ['text-right'],
          tdClass: ['text-right', 'text-nowrap'],
          thStyle: {
            width: '15%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },

        },
      ],
      adisyonFields: [
        {
          key: 'userName',
          label: 'İşlem Sahibi',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '20%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '20%' },
        },
        {
          key: 'toplamTutar',
          label: 'Tutar',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '20%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '20%' },
        },
        {
          key: 'adisyonDurum',
          label: 'Açık / Kapalı',
          thClass: ['text-center'],
          tdClass: ['text-center'],
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'durum',
          label: 'Ödeme Durumu',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'masaAdi',
          label: 'Masa',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '15%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },

        },
        {
          key: 'kayitTarih',
          label: 'Tarih',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '25%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '25%' },

        },
      ],
      havaleFields: [
        {
          key: 'adisyonID',
          label: 'Adisyon ID',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center', 'text-nowrap'],
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'userName',
          label: 'İşlem Sahibi',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center', 'text-nowrap'],
          thStyle: {
            width: '15%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },
        },
        {
          key: 'isim',
          label: 'İsim / Soyİsim',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center', 'text-nowrap'],
          thStyle: {
            width: '15%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },
        },
        {
          key: 'departmanAdi',
          label: 'Departman',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center', 'text-nowrap'],
          thStyle: {
            width: '20%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '20%' },
        },
        {
          key: 'tutar',
          label: 'Tutar',
          thClass: ['text-center'],
          tdClass: ['text-center', 'text-nowrap'],
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'aciklama',
          label: 'Açıklama',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center', 'text-nowrap'],
          thStyle: {
            width: '20%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '20%' },
        },
        {
          key: 'kayitTarih',
          label: 'Tarih',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center', 'text-nowrap'],
          thStyle: {
            width: '15%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },

        },
      ],
      perPage: 25,
      pageOptions: [10, 25, 50, 100, 200, 500],
      toplamKayit: 1,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
    }
  },
  computed: {
    firstTitle() {
      return 'Finans'
    },
    secondTitle() {
      return 'Detaylı Liste'
    },
    tableList: () => store.getters.GET_KASA_RAPORU_TABLES,
    finans: () => ({
      toplamGelir: store.getters.GET_KASA_RAPORU_FINANS.toplamGelir,
      toplamGider: store.getters.GET_KASA_RAPORU_FINANS.toplamGider,
      toplamHavale: store.getters.GET_KASA_RAPORU_FINANS.toplamHavale,
      adisyonSayisi: store.getters.GET_KASA_RAPORU_FINANS.adisyonSayisi,
    }),
  },
  watch: {
    baslangic(newVal) {
      if (newVal && this.bitis) {
        this.isData = true
        this.kasaRapor()
        this.kasaRaporTarih()
      }
    },
    bitis(newVal) {
      if (newVal && this.baslangic) {
        this.isData = true
        this.kasaRapor()
        this.kasaRaporTarih()
      }
    },
    tabs() {
      this.filter = null
      this.toplamKayit = null
      this.totalRows = null
      this.currentPage = 1
      this.perPage = 25
      this.kasaRaporTarih()
    },
  },
  beforeDestroy() {
    store.commit({
      type: 'SET_KASA_RAPORU_FINANS',
      action: 0,
    })
    store.commit({
      type: 'SET_KASA_RAPORU_TABLES',
      action: 0,
    })
  },
  methods: {
    goAdisyonDetay: event => (event.adisyonID && event.adisyonID !== '0' ? router.push(`/order/${event.masaID}/${event.adisyonID}`) : null),
    kasaRapor() {
      const fd = this.postSchema()
      fd.set('methodName', 'kasaRaporu')
      fd.delete('baslangic')
      fd.delete('uzunluk')
      fd.delete('searchKey')
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              store.commit({
                type: 'SET_KASA_RAPORU_FINANS',
                action: 1,
                list: { ...response },
              })
            }
          })
    },
    filterInput(event) {
      this.cardsLoader(true)
      this.currentPage = 1
      const fd = this.postSchema()
      fd.set('searchKey', event || '')
      fd.append('baslangic', 0)
      axios.post('', fd)
          .then(response => {
            store.commit({
              type: 'SET_KASA_RAPORU_TABLES',
              list: response[this.getListName()],
              action: 1,
            })
            this.toplamKayit = store.getters.GET_KASA_RAPORU_TABLES?.length
            this.totalRows = response.toplamKayit
          })
          .finally(() => this.cardsLoader(false))
    },
    currentPageChange(newVal) {
      this.cardsLoader(true)
      const veri = newVal * this.perPage - this.perPage
      const fd = this.postSchema()
      fd.set('baslangic', veri)
      axios.post('', fd)
          .then(response => {
            store.commit({
              type: 'SET_KASA_RAPORU_TABLES',
              list: response[this.getListName()],
              action: 1,
            })
            this.toplamKayit = store.getters.GET_KASA_RAPORU_TABLES?.length
            this.totalRows = response.toplamKayit
          })
          .finally(() => this.cardsLoader(false))
    },
    perPageChange(newVal) {
      this.cardsLoader(true)
      const veri = this.currentPage * newVal - newVal
      const fd = this.postSchema()
      fd.set('baslangic', veri)
      axios.post('', fd)
          .then(response => {
            store.commit({
              type: 'SET_KASA_RAPORU_TABLES',
              list: response[this.getListName()],
              action: 1,
            })
            this.toplamKayit = store.getters.GET_KASA_RAPORU_TABLES?.length
            this.totalRows = response.toplamKayit
          })
          .finally(() => this.cardsLoader(false))
    },
    kasaRaporTarih() {
      this.cardsLoader(true)
      const fd = this.postSchema()
      axios.post('', fd)
          .then(response => {
            store.commit({
              type: 'SET_KASA_RAPORU_TABLES',
              list: response[this.getListName()],
              action: 1,
            })
            this.toplamKayit = store.getters.GET_KASA_RAPORU_TABLES?.length
            this.totalRows = response.toplamKayit
          })
          .finally(() => this.cardsLoader(false))
    },
    getListName() {
      const i = this.tabs
      return i === 0 ? 'gelirList' : i === 1 ? 'giderList' : i === 2 ? 'havaleList' : i === 3 ? 'tahsilatList' : i === 4 ? 'adisyonList' : 'gelirList'
    },
    getMethodName() {
      const i = this.tabs
      return i === 0 ? 'gelirListesiSearch' : i === 1 ? 'giderListesiSearch' : i === 2 ? 'havaleListesiSearch' : i === 3 ? 'tahsilatListesiSearch' : i === 4 ? 'adisyonListesiSearch' : 'gelirListesiSearch'
    },
    postSchema() {
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken || localStorage.getItem('restoranUserToken'))
      fd.append('serviceName', 'rapor')
      fd.append('methodName', this.getMethodName())
      fd.append('searchKey', this.filter || '')
      fd.append('baslangic', 0)
      fd.append('uzunluk', this.perPage)
      fd.append('start', this.baslangic)
      fd.append('end', this.bitis)
      return fd
    },
    cardsLoader(loading) {
      this.$nextTick(() => {
        if (loading) {
          this.$refs['first-card'].showLoading = true
          this.$refs['second-card'].showLoading = true
        } else {
          this.$refs['first-card'].showLoading = false
          this.$refs['second-card'].showLoading = false
        }
      })
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'first-card'].showLoading = false
      }, 1000)
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getOdemeTuru: tur => (tur === '1' ? 'Nakit' : tur === '2' ? 'Havale' : tur === '3' ? 'Kredi Kartı' : tur === '4' ? 'Cari Kredisi' : 'Nakit'),
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style scoped>

.row-card .card-body {
  padding: 0.5rem;
}

.row-card .row {
  place-items: center;
}
</style>
<style>
.pull-up {
  transition: 200ms;
}

.kasa-rapor-table .table td {
  padding: 0.22rem 2rem !important;
}

.tab-content > .active {
  display: block !important;
}
</style>
